export const //
  white = '#ffffff',
  black = '#000000',
  blueGem = '#3c0cbf',
  emerald = '#5ac695',
  selago = '#e8e0fc',
  athensGray = '#f1f1f3',
  frenchGray = '#c1c1c4',
  scarpaFlow = '#525268',
  shark = '#1b1b1d',
  amaranth = '#e43b50',
  neonCarrot = '#fa9943',
  creamCan = '#f2c94c',
  midGrey = '#c1c1c3',
  alto = '#d7d5d5',
  santasGray = '#a3a3ab',
  serenade = '#fff5e5',
  grannyApple = '#c9f2db',
  neonCarott = '#fa9943',
  funGreen = '#008437',
  funkiPorcini = '#ed9996',
  gold = '#6b5000',
  lightGold = '#fff7e0';
