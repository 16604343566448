import { IResourceExport } from './dataProvider';

interface MenuGroup {
  groupName: string;
  resources: IResourceExport[];
  closed?: boolean;
}

export type MenuItems = MenuGroup | IResourceExport;

export type Menu = MenuItems[];

export function isMenuGroup(item: MenuItems): item is MenuGroup {
  return (item as MenuGroup).groupName !== undefined;
}

type ExternalIntegration = {
  id: string;
};

export interface LocalCompany {
  name: string;
  id: string;
  hasFeed?: boolean;
  hasChallenge?: boolean;
  usersCanRegisterWithIdentifier?: boolean;
  externalIntegration?: ExternalIntegration;
  showWellpassCompanyPortalBanner?: boolean;
  hideGoogleWorkspaceSyncTab?: boolean;
  wellPassHrPortalReleaseDate?: string;
}

export interface Manager {
  email: string;
  companies: LocalCompany[];
}

export enum ChallengeStatus {
  Draft = 'DRAFT',
  Scheduled = 'SCHEDULED',
  Published = 'PUBLISHED',
  Started = 'STARTED',
  Finished = 'FINISHED',
}

export type SimplifiedAuthorizedUser = {
  refKey?: string;
  email?: string;
};

export type AuthorizedUser = {
  id: string;
  refKey?: string;
  email?: string;
  companyID: string;
  fromUser?: string;
  fromHrManager?: string;
  fromDomain?: string;
  fromAPI?: string;
  fromGoogle?: boolean;
  fromAmazonS3?: boolean;
};
