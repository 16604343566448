import gql from 'graphql-tag';

const GET_ONE_QUERY = gql`
  query ListAuthorizedUsers(
    $id: ID!
    $page: Int
    $perPage: Int
    $search: String
    $integrationType: [String]
    $responseAsString: Boolean
  ) {
    ListAuthorizedUsers(
      id: $id
      page: $page
      perPage: $perPage
      search: $search
      integrationType: $integrationType
      responseAsString: $responseAsString
    ) {
      authorizedUsers {
        id
        email
        refKey
        fromGoogle
        fromAmazonS3
        fromAPI
        fromDomain
        fromHrManager
        fromUser
      }
      authorizedUsersAsString
      count
      company {
        usersCanRegisterWithIdentifier
        companyInvitationLink
        showIdentifiersOnDashboard
        externalIntegration {
          id
        }
        showWellpassCompanyPortalBanner
        hideGoogleWorkspaceSyncTab
        wellPassHrPortalReleaseDate
      }
    }
  }
`;

export const GetOne = (params: any) => {
  const preparedVariables = {
    id: params.id,
    page: params.page,
    perPage: params.perPage,
    search: params.search,
    integrationType: params.integrationType,
    responseAsString: params.responseAsString,
  };
  return {
    variables: preparedVariables,
    // Override the query
    query: GET_ONE_QUERY,
    parseResponse: (resp: any) => {
      if (!resp.data) return { data: {} };
      return {
        data: params.responseAsString
          ? JSON.parse(resp.data.ListAuthorizedUsers.authorizedUsersAsString || '[]')
          : resp.data.ListAuthorizedUsers.authorizedUsers,
        company: resp.data.ListAuthorizedUsers.company,
        total: resp.data.ListAuthorizedUsers.count,
      };
    },
  };
};

const GET_ONE_COMPANY_LINKS_QUERY = gql`
  query companyById($id: ID!) {
    companyById(id: $id) {
      id
      billingLink
      reportingLink
      name
      hasFeed
      hasChallenge
      usersCanRegisterWithIdentifier
      companyInvitationLink
      showIdentifiersOnDashboard
      externalIntegration {
        id
      }
      showWellpassCompanyPortalBanner
      hideGoogleWorkspaceSyncTab
      wellPassHrPortalReleaseDate
    }
  }
`;

export const GetOneLinks = (params: any) => {
  const preparedVariables = {
    id: params.id,
  };
  return {
    variables: preparedVariables,
    query: GET_ONE_COMPANY_LINKS_QUERY,
    parseResponse: (resp: any) => {
      if (!resp.data) return { data: {} };
      localStorage.setItem('currentCompany', JSON.stringify(resp.data.companyById));
      return {
        data: resp.data.companyById,
        billingLink: resp.data.companyById.billingLink,
        reportingLink: resp.data.companyById.reportingLink,
      };
    },
  };
};
