import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import { Paper } from '../../components';
import { LocalCompany } from '../../@types/common';
import Spinner from '../../components/Spinner';
import { useDataProvider } from 'react-admin';

import ExternalIntegrationPending from './ExternalIntegrationPending';
import ExternalIntegrationActive from './ExternalIntegrationActive';
import NoExternalIntegration from './NoExternalIntegration';
import ListEmails from './ListEmails';
import { lightGold, gold } from '../../utils/colors';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  centeredDiv: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80%',
  },
  button: {
    textTransform: 'none',
  },
  paper: {
    padding: '20px',
    marginTop: '32px',
  },
  paperWithWarning: {
    marginTop: '16px',
  },
  titleSynchronization: {
    marginTop: 0,
    marginBottom: '1em',
    fontSize: '22px',
  },
  buttonCenter: {
    textAlign: 'center',
  },
  syncWrapper: {
    maxWidth: '997px',
  },
  syncWarning: {
    marginTop: '16px',
    padding: '16px',
    background: lightGold,
    boxShadow:
      '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
    display: 'flex',
    borderRadius: '4px',
  },
  warningLogoWrapper: {
    display: 'flex',
    width: '24px',
    flexDirection: 'column',
  },
  warningLogo: {
    width: '24px',
    height: '24px',
  },
  warningExplanation: {
    fontFamily: 'Poppins',
    paddingLeft: '8px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    flexGrow: 1,
  },
  warningTitle: {
    color: gold,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    paddingBottom: '8px',
  },
  warningDetails: {
    color: gold,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },
  warningButtonWrapper: {
    display: 'flex',
    paddingTop: '8px',
  },
  warningButton: {
    fontFamily: 'Poppins',
    borderColor: gold,
    textAlign: 'center',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '0.5px',
    padding: '8px 16px',
    border: `1px solid ${gold}`,
    color: gold,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '36px',
    cursor: 'pointer',
    textDecoration: 'none',
    appearance: 'none',
  },
});

export enum ExternalIntegrationStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
}

export const SynchronizationWhitelists = () => {
  const t = useTranslate();
  const styles = useStyles();
  const [statusExternalIntegration, setStatusExternalIntegration] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [synchronizationData, setSynchronizationData] = useState<any>(null);
  const company = useSelector((state: any) => state.whitelistPaginatedReducers.company);
  const dataProvider = useDataProvider();

  useEffect(() => {
    const currentCompany: LocalCompany = JSON.parse(
      localStorage.getItem('currentCompany') as string
    );
    setIsLoading(true);
    dataProvider
      .getOne('Synchronization', {
        id: currentCompany.id,
      })
      .then(({ data }: { data: any }) => {
        if (data) {
          setSynchronizationData(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dataProvider]);

  useEffect(() => {
    const currentCompany: LocalCompany = JSON.parse(
      localStorage.getItem('currentCompany') as string
    );
    fetch(`${process.env.REACT_APP_API_URL}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
      },
      credentials: 'include',
      body: JSON.stringify({
        variables: { id: currentCompany.id },
        query: `query ExternalIntegrationGoogleGetOne($id: ID!) {\n  data: ExternalIntegrationGoogleGetOne(id: $id) {\n    id\n status  }\n}`,
      }),
    })
      .then(result => result.json())
      .then(e => {
        console.log(e.data);
        if (e.data.data && e.data.data.status === ExternalIntegrationStatus.PENDING) {
          setStatusExternalIntegration(e.data.data.status);
        }

        if (e.data.data && e.data.data.status === ExternalIntegrationStatus.ACTIVE) {
          setStatusExternalIntegration(e.data.data.status);
        }
        console.error(e);
      })
      .catch(e => {
        console.error(e);
      });
  }, []);

  return (
    <div className={styles.syncWrapper}>
      {company?.showWellpassCompanyPortalBanner && (
        <div className={styles.syncWarning}>
          <div className={styles.warningLogoWrapper}>
            <img
              className={styles.warningLogo}
              alt="Warning"
              src={require('../../images/warning.svg')}
            />
          </div>
          <div className={styles.warningExplanation}>
            <div className={styles.warningTitle}>
              {t('google.workspace.syncWarningTitle')}
            </div>
            <div className={styles.warningDetails}>
              {t('google.workspace.syncWarningDetails', {
                date: company.wellPassHrPortalReleaseDate,
              })}
            </div>
            <div className={styles.warningButtonWrapper}>
              <a
                className={styles.warningButton}
                href="https://egym.typeform.com/to/gUN5Yg0M#source=web-portal"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('google.workspace.syncWarningRequestConnection')}
              </a>
            </div>
          </div>
        </div>
      )}
      <Paper
        className={`${styles.paper} ${company?.showWellpassCompanyPortalBanner &&
          styles.paperWithWarning}`}
      >
        <h3 className={styles.titleSynchronization}>{t('google.workspace.title1')}</h3>
        <div>
          <div className={styles.centeredDiv}>
            <h4 className={styles.buttonCenter}>{t('google.workspace.title2')}</h4>
            <p>{t('google.workspace.p1')}</p>
            <div>
              {t('google.workspace.p2')}
              <ul>
                <li>{t('google.workspace.li.1')}</li>
                <li>{t('google.workspace.li.2')}</li>
              </ul>
            </div>
          </div>
          {isLoading && <Spinner />}
          {!isLoading && (
            <>
              {statusExternalIntegration === ExternalIntegrationStatus.PENDING && (
                <div>
                  <ExternalIntegrationPending
                    setStatusExternalIntegration={setStatusExternalIntegration}
                    isLoading={isLoading}
                    synchronizationData={synchronizationData}
                  />
                  <ListEmails
                    synchronizationData={synchronizationData}
                    isLoading={isLoading}
                  />
                </div>
              )}
              {statusExternalIntegration === ExternalIntegrationStatus.ACTIVE && (
                <div>
                  <ExternalIntegrationActive
                    setStatusExternalIntegration={setStatusExternalIntegration}
                  />
                  <ListEmails
                    synchronizationData={synchronizationData}
                    isLoading={isLoading}
                  />
                </div>
              )}
              {!statusExternalIntegration && <NoExternalIntegration />}
            </>
          )}
        </div>
      </Paper>
    </div>
  );
};
